import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './features1.css';

import image1 from '../assets/images/room16.jpg';
import image2 from '../assets/images/room13.jpg';
import image3 from '../assets/images/washroom.jpg';
import image4 from '../assets/images/room7.jpg';
import image5 from '../assets/images/room13.jpg';
import image6 from '../assets/images/room3.jpg';
import image7 from '../assets/images/room6.jpg';
import image8 from '../assets/images/room13.jpg';
import image9 from '../assets/images/room4.jpg';

const Features1 = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const images = [
    [props.feature1ImgSrc1, props.feature1ImgSrc2, props.feature1ImgSrc3],
    [props.feature2ImgSrc1, props.feature2ImgSrc2, props.feature2ImgSrc3],
    [props.feature3ImgSrc1, props.feature3ImgSrc2, props.feature3ImgSrc3],
  ];

  const handleImageChange = (direction) => {
    if (direction === 'next') {
      setActiveImageIndex((prevIndex) => (prevIndex + 1) % 3);
    } else {
      setActiveImageIndex((prevIndex) => (prevIndex - 1 + 3) % 3);
    }
  };

  return (
    <div className="thq-section-padding">
      <div className="features1-container2 thq-section-max-width">
        <div className="features1-image-container">
          <img
            alt={activeTab === 0 ? props.feature1ImgAlt : activeTab === 1 ? props.feature2ImgAlt : props.feature3ImgAlt}
            src={images[activeTab][activeImageIndex]}
            className="features1-image thq-img-ratio-16-9"
          />
          <div className="features1-arrow-container">
            <button onClick={() => handleImageChange('prev')} className="features1-arrow-left">❮</button>
            <button onClick={() => handleImageChange('next')} className="features1-arrow-right">❯</button>
          </div>
        </div>
        <div className="features1-tabs-menu">
          <div onClick={() => { setActiveTab(0); setActiveImageIndex(0); }} className="features1-tab-horizontal1">
            <div className="features1-divider-container1">
              {activeTab === 0 && <div className="features1-container3"></div>}
            </div>
            <div className="features1-content1">
              <h2 className="thq-heading-2">{props.feature1Title}</h2>
              <span className="thq-body-small">{props.feature1Description}</span>
            </div>
          </div>
          <div onClick={() => { setActiveTab(1); setActiveImageIndex(0); }} className="features1-tab-horizontal2">
            <div className="features1-divider-container2">
              {activeTab === 1 && <div className="features1-container4"></div>}
            </div>
            <div className="features1-content2">
              <h2 className="thq-heading-2">{props.feature2Title}</h2>
              <span className="thq-body-small">{props.feature2Description}</span>
            </div>
          </div>
          <div onClick={() => { setActiveTab(2); setActiveImageIndex(0); }} className="features1-tab-horizontal3">
            <div className="features1-divider-container3">
              {activeTab === 2 && <div className="features1-container5"></div>}
            </div>
            <div className="features1-content3">
              <h2 className="thq-heading-2">{props.feature3Title}</h2>
              <span className="thq-body-small">{props.feature3Description}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Features1.defaultProps = {
  feature1ImgAlt: 'Standard room with a view',
  feature1Title: 'Standard Room',
  feature1Description: 'Comfortable bed, Stunning views, Private balcony',
  feature1ImgSrc1: image1,
  feature1ImgSrc2: image2,
  feature1ImgSrc3: image3,
  
  feature2Description: 'King-sized bed, Private balcony or terrace, Mini-fridge and coffee maker, Enhanced Wi-Fi and entertainment options',
  feature2Title: 'Deluxe Room',
  feature2ImgAlt: 'Elegant standard room',
  feature2ImgSrc1: image4,
  feature2ImgSrc2: image5,
  feature2ImgSrc3: image6,
  
  feature3Description: 'Separate living area and bedroom, King-sized bed with luxury linens, Dining area and kitchenette, High-end amenities (smart TV, premium toiletries)',
  feature3Title: 'Suite',
  feature3ImgAlt: 'Cozy room with warm lighting',
  feature3ImgSrc1: image7,
  feature3ImgSrc2: image8,
  feature3ImgSrc3: image9,
};

Features1.propTypes = {
  feature1ImgAlt: PropTypes.string,
  feature1ImgSrc1: PropTypes.string,
  feature1ImgSrc2: PropTypes.string,
  feature1ImgSrc3: PropTypes.string,
  feature2Description: PropTypes.string,
  feature2Title: PropTypes.string,
  feature2ImgAlt: PropTypes.string,
  feature2ImgSrc1: PropTypes.string,
  feature2ImgSrc2: PropTypes.string,
  feature2ImgSrc3: PropTypes.string,
  feature3Description: PropTypes.string,
  feature3Title: PropTypes.string,
  feature3ImgAlt: PropTypes.string,
  feature3ImgSrc1: PropTypes.string,
  feature3ImgSrc2: PropTypes.string,
  feature3ImgSrc3: PropTypes.string,
};

export default Features1;
