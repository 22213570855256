import React, { useState } from 'react';
import Script from 'dangerous-html/react';
import PropTypes from 'prop-types';
import './hero.css';
import ImageModal from './ImageModal'; // Import the ImageModal component
import backg from '../assets/images/back2.jpg';
import image9 from '../assets/images/9.jpg';
import image1 from '../assets/images/1.jpg';
import image2 from '../assets/images/2.jpg';
import image8 from '../assets/images/8.jpg';
import image5 from '../assets/images/5.jpg';
import image3 from '../assets/images/3.jpg';
import image4 from '../assets/images/4.jpg';
import image7 from '../assets/images/7.jpg';
import image6 from '../assets/images/6.jpg';

const Hero = (props) => {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
  ];

  const [isModalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div
      className="hero-header78"
      style={{
        backgroundImage: `url(${backg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
      }}
    >
      <div className="hero-column thq-section-padding thq-section-max-width">
        <div className="hero-content1">
          <h1 className="hero-text1 thq-heading-1">{props.heading1}</h1>
          <p className="hero-text2 thq-body-large">{props.content1}</p>
        </div>
        <div className="hero-actions">
          <button className="thq-button-filled hero-button1">
            <span className="thq-body-small">{props.action1}</span>
          </button>
        </div>
      </div>

      <div className="hero-content2">
        <div className="hero-row-container1 thq-animated-group-container-horizontal">
          <div className="thq-animated-group-horizontal">
            {images.map((img, index) => (
              <img
                key={index}
                alt={props[`image${index + 1}Alt`]}
                src={img}
                className="hero-placeholder-image thq-img-scale thq-img-ratio-1-1"
                onClick={() => openModal(index)} // Open modal on click
              />
            ))}
          </div>
          <div className="thq-animated-group-horizontal">
            {images.map((img, index) => (
              <img
                key={`repeat-${index}`}
                alt={props[`image${index + 1}Alt`]}
                src={img}
                className="hero-placeholder-image thq-img-scale thq-img-ratio-1-1"
                onClick={() => openModal(index)} // Open modal on click
              />
            ))}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <ImageModal
          image={images[currentImageIndex]}
          images={images}
          onClose={closeModal}
          onNext={nextImage}
          onPrev={prevImage}
        />
      )}

      <Script
        html={`<style>
          @keyframes scroll-x {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(-50%);
            }
          }

          .thq-animated-group-horizontal {
            display: flex;
            animation: scroll-x 30s linear infinite; /* Adjust the duration for speed */
          }
        </style>`}
      />
    </div>
  );
};

Hero.defaultProps = {
  image1Alt: 'Hero Image',
  image2Alt: 'Hero Image',
  image3Alt: 'Hero Image',
  image4Alt: 'Hero Image',
  image5Alt: 'Hero Image',
  image6Alt: 'Hero Image',
  heading1: 'Hari Kunj Resort',
  content1: 'Experience the Royal Charm of Rajasthan at Hari Kunj Resort, nestled in the heart of Jodhpur. Where luxury meets Heritage, offering a serene escape amidst to the gateway Thar Desert.',
  action1: 'Book Now',
};

Hero.propTypes = {
  heading1: PropTypes.string,
  content1: PropTypes.string,
  action1: PropTypes.string,
  image1Alt: PropTypes.string,
  image2Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  image4Alt: PropTypes.string,
  image5Alt: PropTypes.string,
  image6Alt: PropTypes.string,
};

export default Hero;
