import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBed, faConciergeBell, faImages, faEnvelope, faHotel } from '@fortawesome/free-solid-svg-icons';
import './navbar.css';

const Navbar = (props) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="navbar-container">
      <div className="navbar-navbar-interactive">
        {/* Hotel icon on the left for mobile */}
        <div className="navbar-hotel-icon">
          <FontAwesomeIcon icon={faHotel} className="hotel-icon" />
        </div>

        {/* Mobile burger menu on the right */}
        <div className="navbar-burger-menu" onClick={toggleMobileMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className="navbar-desktop-menu">
          <nav className="navbar-links1">
            <span className="thq-link thq-body-small">
              <FontAwesomeIcon icon={faHome} /> {props.link1}
            </span>
            <span className="thq-link thq-body-small">
              <FontAwesomeIcon icon={faBed} /> {props.link2}
            </span>
            <span className="thq-link thq-body-small">
              <FontAwesomeIcon icon={faConciergeBell} /> {props.link3}
            </span>
            <span className="thq-link thq-body-small">
              <FontAwesomeIcon icon={faImages} /> {props.link4}
            </span>
            <span className="thq-link thq-body-small">
              <FontAwesomeIcon icon={faEnvelope} /> {props.link5}
            </span>
          </nav>
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className={`navbar-mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <div className="close-button" onClick={toggleMobileMenu}>
            &times;
          </div>
          <div className="navbar-nav">
            <nav className="navbar-links2">
              <span className="thq-link thq-body-small">
                <FontAwesomeIcon icon={faHome} /> {props.link1}
              </span>
              <span className="thq-link thq-body-small">
                <FontAwesomeIcon icon={faBed} /> {props.link2}
              </span>
              <span className="thq-link thq-body-small">
                <FontAwesomeIcon icon={faConciergeBell} /> {props.link3}
              </span>
              <span className="thq-link thq-body-small">
                <FontAwesomeIcon icon={faImages} /> {props.link4}
              </span>
              <span className="thq-link thq-body-small">
                <FontAwesomeIcon icon={faEnvelope} /> {props.link5}
              </span>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};

Navbar.defaultProps = {
  link1: 'Home',
  link2: 'Rooms',
  link3: 'Services',
  link4: 'Gallery',
  link5: 'Contact',
};

Navbar.propTypes = {
  link1: PropTypes.string,
  link2: PropTypes.string,
  link3: PropTypes.string,
  link4: PropTypes.string,
  link5: PropTypes.string,
};

export default Navbar;
