import React from 'react'
import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Hero from '../components/hero'
import Features1 from '../components/features1'
import CTA from '../components/cta'
import Features2 from '../components/features2'
// import Pricing from '../components/pricing'
import Steps from '../components/steps'
import Testimonial from '../components/testimonial'
import Contact from '../components/contact'
import Footer from '../components/footer'
import './home.css'

// Import the logo
import logo from '../assets/images/teblogo.png'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Hari Kunj Resort</title>
        <link rel="icon" href={logo} /> {/* Set the logo as the favicon */}
      </Helmet>
      <Navbar logo={logo}></Navbar> {/* Pass the logo as a prop */}
      <Hero></Hero>
      <Features1></Features1>
      <CTA></CTA>
      <Features2></Features2>
      {/* <Pricing></Pricing> */}
      <Steps></Steps>
      <Testimonial></Testimonial>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  )
}

export default Home
