// ImageModal.js
import React from 'react';
import PropTypes from 'prop-types';
import './ImageModal.css'; // Create a CSS file for styling

const ImageModal = ({ image, images, onClose, onNext, onPrev }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>✖</button>
        <div className="modal-image-container">
          <img src={image} alt="Selected" className="modal-image" />
        </div>
        <div className="modal-navigation">
          <button className="modal-nav-button" onClick={onPrev}>❮</button>
          <button className="modal-nav-button" onClick={onNext}>❯</button>
        </div>
      </div>
    </div>
  );
};

ImageModal.propTypes = {
  image: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
};

export default ImageModal;
