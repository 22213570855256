import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './features2.css';

import image1 from '../assets/images/WhatsApp Image 2024-09-22 at 16.51.50_635ff2b8.jpg';
import image2 from '../assets/images/7.jpg';
import image3 from '../assets/images/8.jpg';
// import image4 from '../assets/images/IMG_20240905_142921_734.jpg';
// import image5 from '../assets/images/IMG_20240905_142940_417.jpg';
// import image6 from '../assets/images/IMG_20231123_183140_239.jpg';

const Features2 = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="thq-section-padding">
      <div className="features2-container2 thq-section-max-width">
        <div className="features2-tabs-menu">
          <div
            onClick={() => setActiveTab(0)}
            className="features2-tab-horizontal1"
          >
            <div className="features2-divider-container1">
              {activeTab === 0 && <div className="features2-container3"></div>}
            </div>
            <div className="features2-content1">
              <h2 className="thq-heading-2">{props.feature1Title}</h2>
              <span className="thq-body-small">
                {props.feature1Description}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(1)}
            className="features2-tab-horizontal2"
          >
            <div className="features2-divider-container2">
              {activeTab === 1 && <div className="features2-container4"></div>}
            </div>
            <div className="features2-content2">
              <h2 className="thq-heading-2">{props.feature2Title}</h2>
              <span className="thq-body-small">
                {props.feature2Description}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(2)}
            className="features2-tab-horizontal3"
          >
            <div className="features2-divider-container3">
              {activeTab === 2 && <div className="features2-container5"></div>}
            </div>
            <div className="features2-content3">
              <h2 className="thq-heading-2">{props.feature3Title}</h2>
              <span className="thq-body-small">
                {props.feature3Description}
              </span>
            </div>
          </div>
        </div>
        <div className="features2-image-container">
          {activeTab === 0 && (
            <img
              alt={props.feature1ImgAlt}
              src={image1}
              className="features2-image1 thq-img-ratio-16-9"
            />
          )}
          {activeTab === 1 && (
            <img
              alt={props.feature2ImgAlt}
              src={image2}
              className="features2-image2 thq-img-ratio-16-9"
            />
          )}
          {activeTab === 2 && (
            <img
              alt={props.feature3ImgAlt}
              src={image3}
              className="features2-image3 thq-img-ratio-16-9"
            />
          )}
        </div>
      </div>
    </div>
  );
};

Features2.defaultProps = {
  feature3Description: 'Audio-visual equipment and catering services, Event planning and concierge support',
  feature1ImgAlt: 'Luxurious Accommodations Image Alt Text',
  feature1Description: 'Enjoy fresh, organic produce from our on-site farm, enhancing your stay with the flavors of nature.',
  feature3ImgAlt: 'Relaxing Spa Services Image Alt Text',
  feature2Title: 'Garden',
  feature1Title: 'Farming',
  feature2Description: 'Escape to tranquility in our lush, beautifully landscaped garden—a perfect retreat for relaxation.',
  feature3Title: 'Conference and Event Spaces',
  feature2ImgAlt: 'Fine Dining Experience Image Alt Text',
};

Features2.propTypes = {
  feature3Description: PropTypes.string,
  feature1ImgAlt: PropTypes.string,
  feature1Description: PropTypes.string,
  feature3ImgAlt: PropTypes.string,
  feature2Title: PropTypes.string,
  feature1Title: PropTypes.string,
  feature2Description: PropTypes.string,
  feature3Title: PropTypes.string,
  feature2ImgAlt: PropTypes.string,
};

export default Features2;
