import React from 'react';
import PropTypes from 'prop-types';
import './steps.css';

// Importing images
import KaylanaLake from '../assets/images/Kaylana-Lake.jpg';
import UmaidBhawanPalace from '../assets/images/Umaid-Bhawan-Palace.jpg';
import MachiaBiologicalPark from '../assets/images/Machia-Biological-Park.jpg';
import MandoreGarden from '../assets/images/Shyam-Manohar-Prabhu-Mandir.jpg';
import UmaidSagarBandh from '../assets/images/Ummed-Sagar-Bandh.jpg';
import MehrangarhFort from '../assets/images/Mehrangarh-Fort.jpg';

const Steps = (props) => {
  return (
    <div className="steps-container1 thq-section-padding">
      <hr className="section-divider" />
      <div className="steps-max-width thq-section-max-width">
        <div className="steps-container2 thq-grid-2">
          <div className="steps-section-header">
            <h2 className="thq-heading-2">
              Discover Nearby Attractions within 10 Km
            </h2>
            <p className="thq-body-large">
              Unveil the hidden gems just a stone's throw away from our hotel, where adventure and serenity blend seamlessly.
            </p>
            {/* Show this paragraph only on desktop */}
            <p className="hidden-on-mobile thq-body-large">
              Our hotel is perfectly situated in the heart of Jodhpur, offering easy access to some of the city's most captivating attractions.
            </p>
            <p className="hidden-on-mobile thq-body-large">
              Within a 10 km radius, you can explore the serene beauty of Kaylana Lake or marvel at the wildlife at Machia Biological Park. 
              History enthusiasts will be drawn to the majestic Umaid Bhawan Palace and the ancient splendor of Mehrangarh Fort, both icons of Jodhpur's regal past.
            </p>
            <p className="hidden-on-mobile thq-body-large">
              For a touch of nature and history combined, a visit to the verdant Mandore Garden is a must. Whether you're seeking relaxation, adventure, or cultural discovery, 
              our location provides the perfect gateway to the wonders of Jodhpur.
            </p>

            {/* Image Section - Visible only on desktop */}
            <div className="image-gallery hidden-on-mobile">
              <div className="image-frame kaylana-lake">
                <img src={KaylanaLake} alt="Kaylana Lake" className="attraction-image" />
                <div className="image-tooltip">Kaylana Lake</div>
              </div>
              <div className="image-frame machia-biological-park">
                <img src={MachiaBiologicalPark} alt="Machia Biological Park" className="attraction-image" />
                <div className="image-tooltip">Machia Biological Park</div>
              </div>
              <div className="image-frame umaid-bhawan-palace">
                <img src={UmaidBhawanPalace} alt="Umaid Bhawan Palace" className="attraction-image" />
                <div className="image-tooltip">Umaid Bhawan Palace</div>
              </div>

              <div className="image-frame mandore-garden">
                <img src={UmaidSagarBandh} alt="Shyam Manohar Prabhu Mandir" className="attraction-image" />
                <div className="image-tooltip">Umaid Sagar Bandh</div>
              </div>
              <div className="image-frame mandore-garden">
                <img src={MandoreGarden} alt="Shyam Manohar Prabhu Mandir" className="attraction-image" />
                <div className="image-tooltip">Shyam Manohar Prabhu Mandir</div>
              </div>
              <div className="image-frame machia-biological-park">
                <img src={MehrangarhFort} alt="Machia Biological Park" className="attraction-image" />
                <div className="image-tooltip">Mehrangarh Fort</div>
              </div>


            </div>
          </div>
          <div className="steps-container3">
            {/* Your existing cards go here */}
            <div className="steps-container4 thq-card kaylana-lake">
              <h2 className="thq-heading-2">
                <a href="https://maps.app.goo.gl/zqkKvd8bUFF9nP6t9" target="_blank" rel="noopener noreferrer">
                  {props.step1Title}
                </a>
              </h2>
              <span className="steps-text14 thq-body-small">
                {props.step1Description}
              </span>
              <label className="steps-text15 thq-heading-3">01</label>
            </div>
            <div className="steps-container5 thq-card">
              <h2 className="thq-heading-2">
                <a href="https://maps.app.goo.gl/GNoMVzqcErg94cqr9" target="_blank" rel="noopener noreferrer">
                  {props.step2Title}
                </a>
              </h2>
              <span className="steps-text17 thq-body-small">
                {props.step2Description}
              </span>
              <label className="steps-text18 thq-heading-3">02</label>
            </div>
            <div className="steps-container6 thq-card">
              <h2 className="thq-heading-2">
                <a href="https://maps.app.goo.gl/2RH6fUzK5aaDtGFm6" target="_blank" rel="noopener noreferrer">
                  {props.step3Title}
                </a>
              </h2>
              <span className="steps-text20 thq-body-small">
                {props.step3Description}
              </span>
              <label className="steps-text21 thq-heading-3">03</label>
            </div>
            <div className="steps-container7 thq-card">
              <h2 className="thq-heading-2">
                <a href="https://maps.app.goo.gl/tr5ZtymKCsSXWPaS7" target="_blank" rel="noopener noreferrer">
                  {props.step4Title}
                </a>
              </h2>
              <span className="steps-text23 thq-body-small">
                {props.step4Description}
              </span>
              <label className="steps-text24 thq-heading-3">04</label>
            </div>
            <div className="steps-container6 thq-card">
              <h2 className="thq-heading-2">
                <a href="https://maps.app.goo.gl/cbQfbyx7cxprCC6UA" target="_blank" rel="noopener noreferrer">
                  {props.step5Title}
                </a>
              </h2>
              <span className="steps-text20 thq-body-small">
                {props.step5Description}
              </span>
              <label className="steps-text21 thq-heading-3">05</label>
            </div>
            <div className="steps-container7 thq-card">
              <h2 className="thq-heading-2">
                <a href="https://maps.app.goo.gl/P2kgjfLF3ef4m8Z97" target="_blank" rel="noopener noreferrer">
                  {props.step6Title}
                </a>
              </h2>
              <span className="steps-text23 thq-body-small">
                {props.step6Description}
              </span>
              <label className="steps-text24 thq-heading-3">06</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Steps.defaultProps = {
  step1Description:
    'A picturesque man-made lake, perfect for a serene escape and bird-watching amidst nature.',
  step1Title: 'Kaylana Lake',

  step2Title: 'Machia Biological Park',
  step2Description:
    'A wildlife park offering close encounters with native species, ideal for nature lovers.',

  step3Title: 'Umaid Bhawan Palace',
  step3Description:
    'A stunning palace that is part museum and part luxury hotel, showcasing the royal heritage of Jodhpur.',

  step4Title: 'Ummed Sagar Bandh',
  step4Description:
    'Ummed Sagar Bandh, a serene reservoir near Jodhpur, is located just 1.5 km from the resort, offering a peaceful escape and a vital water source for the region.',

  step5Title: 'Shyam Manohar Prabhu Mandir',
  step5Description:
    'Shyam Manohar Prabhu Mandir, a revered temple known for its spiritual ambiance, is located just 500 meters from the resort.',

  step6Title: 'Mehrangarh Fort',
  step6Description:
    'A magnificent fortress towering over the city of Jodhpur, it boasts stunning architecture and rich history. Located just 6.5 km from our hotel, it offers breathtaking views and an unforgettable experience.',
};

Steps.propTypes = {
  step1Description: PropTypes.string,
  step2Description: PropTypes.string,
  step2Title: PropTypes.string,
  step1Title: PropTypes.string,
  step3Description: PropTypes.string,
  step3Title: PropTypes.string,
  step4Description: PropTypes.string,
  step4Title: PropTypes.string,
  step5Description: PropTypes.string,
  step5Title: PropTypes.string,
};

export default Steps;
  